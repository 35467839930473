<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">
    <ul class="price-list flex-row-nowrap align-items-baseline">
      <li class="default">
        <!-- BESPOKE PAL: Show price on request label if product price is 0 -->
        <span v-if="prices.rawBasePrice != 0">{{ prices.price }}</span>
        <span class="price-on-request" v-else v-translation="{ type: 'label', code: 'price_on_request'}"></span>
        <!-- END BESPOKE PAL -->
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePrice }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  computed: {
    ...mapGetters(['showPrices'])
  }
}
</script>

<style>

</style>
