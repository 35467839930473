<template>
  <div class="utlz-deleted-table">
      <ul class="list-inline vertical-table">
        <li
          v-for="item in deletedListItems"
          :key="item.Code"
          :data-prod-code="item.Code"
          :data-row-order="item.rowOrder"
          :class="{ 'deleted-item': item.wasDeleted }"
          class="list-item-default deleted-list-item"
        >
          <span class="prod-code">{{ item.Code }}</span>
          <span class="prod-desc">{{ item.Description }}</span>
          <i @click="addToSortedList(item.Code)" class="undo-delete-btn fa fa-undo"></i>
        </li>
      </ul>

    </div>
</template>

<script>
export default {
  props: ['deletedListItems'],
  methods: {
    addToSortedList (prodCode) {
      this.$emit('addToSortedList', prodCode);
    }
  }
}
</script>

<style>

</style>
