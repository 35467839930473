<template>

    <div class="utlz-sorting-table">
      <ul id="sorting-table" class="sortable-list list-inline vertical-table">
        <li
          v-for="item in sortedListItems"
          :key="item.Code"
          :data-prod-code="item.Code"
          :data-row-order="item.rowOrder"
          class="list-item-default sorting-list-item"
        >
          <i class="drag-icon fa fa-ellipsis-v"></i>
          <span class="prod-code">{{ item.Code }}</span>
          <span class="prod-desc">{{ item.Description }}</span>
          <i @click="deleteFromOrderList(item.Code)" class="delete-btn fa fa-eye-slash"></i>
        </li>
      </ul>
        <div class="sorting-table-header">

        </div>
    </div>
</template>

<script>
import { OrderlistItem } from './../models/orderlist-item.model';
export default {
  props: ['sortedListItems'],
  methods: {
    updateList (ev, ui) {
      let prodCodesInOrder = [];
      $('#sorting-table li').each(function () {
        prodCodesInOrder.push($(this).data('prod-code'));
      });
      const prodCode = $(ui.item).data('prod-code');
      this.$emit('updateSortedListItems', { prodCodesInOrder });
    },
    deleteFromOrderList (prodCode) {
      this.$emit('deleteFromOrderList', prodCode);
    }
  },
  mounted () {
    $('#sorting-table').sortable({
      update: this.updateList
    });
  }
}
</script>
