<template>
  <div class="product-banners">
    <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
      <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
        <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
          {{ bannerVal.Text }}
        </span>
        <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    banners: { type: Object, required: false }
  },
  computed: {
    productImageBanners () {
      return this.banners.filter(banner => banner.show === true);
    }
  }
}
</script>

<style>
</style>
