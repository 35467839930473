<template>
  <div class="utlz-orderlist-modal modal bs-example-modal-lg in">
    <div class="modal-dialog modal-lg">

      <div class="modal-header">
        <h2 class="modal-title" v-translation="{ type: 'title', code: 'title_orderlist_modal' }"></h2>
      </div>

        <ul class="nav nav-tabs">
            <li class="active">
                <a data-toggle="tab" href="#sorting" aria-expanded="true" v-translation="{ type: 'label', code: 'label_sorting'}"></a>
            </li>
            <li class="deleted-tab">
                <a data-toggle="tab" href="#deleting" aria-expanded="false" :class="{ 'has-deleted': itemsWereDeleted }" v-translation="{ type: 'label', code: 'label_invisible'}"></a>
            </li>
        </ul>

      <div class="modal-msg-ph">
        <transition name="fade-default">
          <div class="modal-msg alert alert-danger" v-show="showDeletedProductMessage">
            <span v-translation="{ type: 'label', code: 'label_msg_deleted_product', interpolations: [deletedProductCode] }"></span>
          </div>
        </transition>
      </div>

      <div class="modal-body">
        <div class="tab-content">
          <div class="tab-pane fade active in" id="sorting">
            <sorting-table
              :sortedListItems="sortedListCopy"
              @updateSortedListItems="updateSortedListItems($event)"
              @deleteFromOrderList="deleteFromOrderList($event)"
            ></sorting-table>
          </div>
          <div class="tab-pane fade" id="deleting">
            <deleted-table
              @addToSortedList="addToSortedList($event)"
              :deletedListItems="deletedListCopy">
            </deleted-table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="footer-content">
          <a class="modal-btn btn-u cancel" @click="cancelClicked" v-translation="{ type: 'label', code: 'button_cancel'}">
            <span ></span>
          </a>
          <a @click="saveTables()" class="modal-btn btn-u save" v-translation="{ type: 'label', code: 'button_save'}">
          </a>
        </div>
      </div>
    </div>
    <div class="modal-backdrop in"></div>
  </div>
</template>

<script>
import SortingTable from './SortingTable';
import DeletedTable from './DeletedTable';
import { mapGetters, mapActions } from 'vuex';
export default {
  data () {
    return {
      sortedListCopy: [],
      deletedListCopy: [],
      newSortedListItems: [],
      newDeletedListItemCodes: [],
      showDeletedProductMessage: false,
      deletedProductCode: ''
    }
  },
  components: {
    'sorting-table': SortingTable,
    'deleted-table': DeletedTable
  },
  computed: {
    ...mapGetters('orderList', ['sortedListItems', 'deletedListItems']),
    itemsWereDeleted () {
      return this.deletedListCopy.some(item => item.wasDeleted === true);
    }
  },
  methods: {
    ...mapActions('orderList', ['saveCustomOrderlist']),
    cancelClicked () {
      this.$emit('changeModalVisibility');
    },
    updateSortedListItems ({ prodCodesInOrder }) {
      let sortedListItems = [...this.sortedListCopy];
      let newSortedListItems = [];
      prodCodesInOrder.forEach(prodCode => {
        sortedListItems.forEach(listItem => {
          if (listItem.Code == prodCode) { newSortedListItems.push(listItem); }
        });
      });
      this.sortedListCopy = newSortedListItems;
    },
    deleteFromOrderList (prodCode) {
      // Remove from sortable list
      const sortedListItems = [...this.sortedListCopy];
      const newSortedListItems = sortedListItems.filter(item => item.Code !== prodCode);
      this.sortedListCopy = newSortedListItems;
      // Add to deleted list
      const deletedItem = sortedListItems.find(item => item.Code === prodCode);
      deletedItem.wasDeleted = true;
      this.deletedListCopy.push(deletedItem);
      // Update view messages
      this.deletedProductCode = deletedItem.Code;
      this.toggleDeletedProductMsg();
    },
    addToSortedList (prodCode) {
      let deletedItems = [...this.deletedListCopy];
      let deletedItem = deletedItems.filter(item => item.Code === prodCode)[0];
      this.sortedListCopy.push(deletedItem);
      this.deletedListCopy = deletedItems.filter(item => item.Code !== prodCode);
    },
    toggleDeletedProductMsg () {
      this.showDeletedProductMessage = true;
      setTimeout(() => {
        this.showDeletedProductMessage = false;
      }, 2000);
    },
    saveTables () {
      this.saveCustomOrderlist({ sortedListItems: this.sortedListCopy, deletedListItems: this.deletedListCopy });
      this.$emit('changeModalVisibility', false);
    }
  },
  mounted () {
    this.sortedListCopy = [...this.sortedListItems];
    this.deletedListCopy = [...this.deletedListItems];
  }
};
</script>
