<template>
    <div class="orderlist-item orderlist-row">
        <div class="cell prod-description">
          <span class="prod-desc-value">{{ OrderlistItem.Description }}</span>
          <span class="prod-code-value">{{ OrderlistItem.Code }}</span>
          <span v-if="showMobile" class="prod-unit-mobile">
            {{OrderlistItem.UnitName}}
          </span>
        </div>
        <div v-if="!showMobile" class="cell prod-unit">
            {{OrderlistItem.UnitName}}
        </div>
        <div class="cell prod-quantity">
          <utlz-quantity-buttons
            :initialQuantity="OrderlistItem.Quantity"
            @quantityChanged="quantityChanged($event)">
          </utlz-quantity-buttons>
        </div>
        <div class="cell weeks">
          <div class="week">
            <span v-if="showMobile" class="week-label">-1w</span>
            <span class="week-value">{{ OrderlistItem.Bought1WeekAgo }}</span>
          </div>
          <div class="week">
            <span v-if="showMobile" class="week-label">-2w</span>
            <span class="week-value">{{ OrderlistItem.Bought2WeekAgo }}</span>
          </div>
          <div class="week">
            <span v-if="showMobile" class="week-label">-3w</span>
            <span class="week-value">{{ OrderlistItem.Bought3WeekAgo }}</span>
          </div>
          <div class="week">
            <span v-if="showMobile" class="week-label">-4w</span>
            <span class="week-value">{{ OrderlistItem.Bought4WeekAgo }}</span>
          </div>
        </div>
        <div class="cell cart">
          <div class="cart-wrapper">
            <i class="fa uws-cart"></i>
            <span class="counter btn-u">{{ OrderlistItem.InCart }}</span>
          </div>
        </div>
    </div>
</template>
<script>
import { OrderlistItem } from './../models/orderlist-item.model';
import { mapMutations, mapGetters } from 'vuex';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
export default {
  components: {
    'utlz-quantity-buttons': QuantityButtons
  },
  computed: {
    ...mapGetters(['screenWidth']),
    showMobile () {
      return this.screenWidth < 991;
    }
  },
  methods: {
    ...mapMutations('orderList', ['setOrderQuantity']),
    quantityChanged ({ quantity }) {
      this.setOrderQuantity({ productcode: this.OrderlistItem.Code, quantity })
    }
  },
  props: {
    OrderlistItem: { type: OrderlistItem, required: false }
  },
  data () {
    return {
      quantity: 0
    }
  }
}
</script>
