export class OrderlistItem {
  constructor (orderlistItem) {
    this.Code = orderlistItem.Code;
    this.Description = orderlistItem.Description;
    this.UnitName = orderlistItem.UnitName;
    this.UnitCode = orderlistItem.UnitCode;
    this.UnitPrice = orderlistItem.UnitPrice;
    this.PriceOld = orderlistItem.PriceOld;
    this.PriceChangeDate = orderlistItem.PriceChangeDate;
    this.Bought1WeekAgo = orderlistItem.Bought1WeekAgo;
    this.Bought2WeekAgo = orderlistItem.Bought2WeekAgo;
    this.Bought3WeekAgo = orderlistItem.Bought3WeekAgo;
    this.Bought4WeekAgo = orderlistItem.Bought4WeekAgo;
    this.ActionProduct = orderlistItem.ActionProduct;
    this.SortNumber = orderlistItem.SortNumber;
    this.Quantity = orderlistItem.Quantity;
    this.InCart = orderlistItem.InCart;
    this.IsSorted = orderlistItem.IsSorted;
  }
}
