<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">
      <div class="utlz-col-md-5 column-left">
        <utlz-gallery :product="productDetails"></utlz-gallery>
        <!-- BESPOKE PAL: Added food comment, psinfood and more info button -->

        <div v-if="psinfoodData.comments" class="food-comments">
          <p>{{ psinfoodData.comments }}</p>
        </div>

        <template v-if="!psinfoodData.psinfood">
          <a :href="psinfoodData.moreInfoUrl" 
            v-translation="{ type: 'label', code: 'link_more_product_info' }" 
            class="fa fa-file-text more-prod-info-btn btn-u">
          </a>
        </template>
        <template v-else>
          <a :href="psinfoodData.psinfoodLink" target="_blank">
            <div class="food_book">
              <ul class="list-inline food_book">
                <li class="logo">
                  <img :src="psinfoodData.psinfoodImgUrl"/>
                </li>
                <li class="text" v-translation="{ type: 'text', code: 'text_foodbook' }">
                </li>
              </ul>
            </div>
          </a>
        </template>
        <!-- END BESPOKE PAL -->
      </div>
      <div class="utlz-col-md-8 column-right product-details-column">
          <div class="product-information">
            <!-- BESPOKE PAL: Added product code label and moved it above title -->
            <div class="product-code">
              <span v-translation="{ type: 'label', code: 'label_product_code'}"></span>:
              <span class="code-value" >{{productDetails.id}}</span>
            </div>
            <!-- END BESPOKE PAL -->
            <h1>{{productDetails.shortDescription}}</h1> 
            
            <!-- BESPOKE PAL: Added ingredients -->            
            <p v-if="psinfoodData.ingredients" class="ingredients">{{ psinfoodData.ingredients }}</p>            
            <!-- END BESPOKE PAL -->

            <div class="ph-product-price" v-if="productDetails.prices.length">
                <utlz-product-price
                    :prices="productDetails.prices[0]"
                ></utlz-product-price>
            </div>

            <utlz-tabs :tabs="tabs">

              <template v-slot:description>
                <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
                  <div ref="productDescription" class="prod-desc-wrapper">
                    <div class="product-long-description" v-html="productDetails.longDescription">
                    </div>
                  </div>
                </div>
                <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
                  <div v-if="!expanded" class="read-more">
                    <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                  <div v-if="expanded" class="read-less">
                    <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </a>
              </template>

              <template v-slot:videos v-if="videos.length">
                <div class="product-videos">
                  <utlz-product-videos :videos="videos"></utlz-product-videos>
                </div>
              </template>

              <template v-slot:documents v-if="documents.length">
                <div class="product-documents">
                  <utlz-product-documents :documents="documents"></utlz-product-documents>
                </div>
              </template>

            </utlz-tabs>            

            <utlz-group-dropdown :variants="productVariants" />

            <utlz-product-stock
              :product="productDetails"
              :stock="productDetails.stock"
              :stockTotal="productDetails.stock"
            ></utlz-product-stock>

            <!-- BESPOKE PAL: Added useButtonText="true" and :showUnitDisplay="true" -->
            <div class="order-product">
              <utlz-order-product
                v-if="showOrderProductControl"
                :product="productDetails"
                :useOrderComment="useOrderLineComments"
                :useButtonText="true"
                :showUnitDisplay="true"
                >
                </utlz-order-product>

                <transition name="fade">
                  <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                    <i class="fa fa-warning"></i>
                    <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                  </p>
                </transition>
            </div>
            <!-- END BESPOKE PAL -->

            <div class="favorite">
              <utlz-favorites-control v-if="showFavorites" :product="productDetails" :useDescription="true"></utlz-favorites-control>
            </div>

            <!-- BESPOKE PAL: Added psinfood accordions -->
            <ul class="collapse-list">
              <li v-if="containedTypes.length" class="collapse-item">
                <div class="contained-types content-wrapper">
                  <h2 class="tab-content-title">
                    <a data-toggle="collapse" href="#collapseContainedtypes" role="button" aria-expanded="false" aria-controls="collapseContainedtypes" class="collapsed">                        
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        {{ psinfoodData.containsTypesLabel }}
                    </a>
                  </h2>
                  <div id="collapseContainedtypes" class="collapse" aria-expanded="false">
                    <div class="tab-content-wrapper">
                        <ul class="properties-list">                            
                            <li v-for="(type, index) of containedTypes" :key="'list-type' + index">                                                  
                                <span>{{ type }}</span>
                            </li>                                  
                        </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li v-if="containsNot.length" class="collapse-item">
                <div class="does-not-contain content-wrapper">
                  <h2 class="tab-content-title">
                    <a data-toggle="collapse" href="#collapseDoesnotcontain" role="button" aria-expanded="false" aria-controls="collapseContainedtypes" class="collapsed">                        
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        {{ psinfoodData.containsNotLabel }}
                    </a>
                  </h2>
                  <div id="collapseDoesnotcontain" class="collapse" aria-expanded="false">
                    <div class="tab-content-wrapper">
                        <ul class="properties-list">                            
                            <li v-for="(containedItem, index) of containsNot" :key="'list-contains-not' + index">                                                  
                                <span>{{ containedItem }}</span>
                            </li>                                  
                        </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li v-if="containsTraces.length" class="collapse-item">
                <div class="does-not-contain content-wrapper">
                  <h2 class="tab-content-title">
                    <a data-toggle="collapse" href="#collapseTraces" role="button" aria-expanded="false" aria-controls="collapseContainedtypes" class="collapsed">                        
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        {{ psinfoodData.containsTracesLabel }}
                    </a>
                  </h2>
                  <div id="collapseTraces" class="collapse" aria-expanded="false">
                    <div class="tab-content-wrapper">
                        <ul class="properties-list">                            
                            <li v-for="(containedTrace, index) of containsTraces" :key="'list-contains-trace' + index">                                                  
                                <span>{{ containedTrace }}</span>
                            </li>                                  
                        </ul>
                    </div>
                  </div>
                </div>
              </li>             
            </ul>
            <!-- END BESPOKE PAL: End psinfood accordions -->

          </div>
      </div>

      <div class="utlz-col-12 margin-bottom-30"></div>

      <!-- BESPOKE PAL: Moved specifications down, and wrapped it in div.utlz-col-12 -->
      <div class="utlz-col-12 specs-wrapper">
        <div class="product-specifications" v-if="productProperties.length > 0">
          <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2>
          <div class="spec-list">
            <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
              <div class="spec-desc">
                {{prop.key}}
              </div>
              <div class="spec-value">
                {{prop.value}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END BESPOKE PAL -->

      <div v-if="showOrderProductControl && productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div>

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments
  },  
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: true, visible: true },
        { id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false, visible: false },
        { id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false, visible: false }
      ],
      // BESPOKE  
      psinfoodData: window.vue.modules.productDetails.psinfood
      // END BESPOKE
    }
  },
  watch: {
    documents (val) { this.tabs[1].visible = val.length > 0; },
    videos (val) { this.tabs[2].visible = val.length > 0; }
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts', 'videos']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize']),
    ...mapGetters('productDetails', ['documents']),
    useCollapse () {
      return this.productDescriptionHeight > 150;
    },
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      return Object.keys(this.productDetails.properties).map(x => {
        return { key: x, value: this.productDetails.properties[x] }
      });
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    },
    // BESPOKE PAL
    containedTypes () {
      return this.psinfoodData.containsTypes.length ? this.psinfoodData.containsTypes.split(';') : [];
    },
    containsNot () {
      return this.psinfoodData.containsNot ? this.psinfoodData.containsNot.split(';') : [];
    },
    containsTraces () {
      return this.psinfoodData.containsTraces ? this.psinfoodData.containsTraces.split(';') : [];
    },
    // END BESPOKE PAL
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails'])
  }
}
</script>

<style>

</style>
