<template>
  <div class="orderlist-orderbar">
    <div class="container">
      <div class="row">
        <div class="col-md-12 btns-wrapper">
          <a class="orderbar-btn btn-u" @click="addProductsToShoppingCart" v-translation="{ type: 'button', code: 'button_orderlist_addtocart' }"></a>
          <div class="shopping-cart-wrapper">
            <i class="fa uws-cart"></i>
            <span class="btn-u counter">{{ totalProductsInCart }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    totalProductsInCart: { type: Number, default: 0 }
  },
  methods: {
    ...mapActions('orderList', ['addProductsToShoppingCart'])
  }
}
</script>
