<template>
    <div class="utlz-orderlist row">
        <div class="col-md-12">
            <div class="header-row">
              <h1 v-translation="{ type: 'title', code: 'title_orderlist'}"></h1>
              <div class="header-right">
                <a class="orderlist-button btn-u" v-translation="{ type: 'label', code: 'button_reset'}" @click="clearQuantities"></a>
                <a @click="printOrderlist" class="print-list btn-u">
                  <i class="fa fa-print" aria-hidden="true"></i>
                  <span v-translation="{ type: 'label', code: 'button_print_list' }"></span>
                </a>
              </div>
            </div>

            <div class="sorting-row">

              <div class="search-wrapper">
                <h3 v-translation="{ type: 'label', code: 'label_search_orderlist'}"></h3>
                <input type="text" @keyup.enter="changeSearchText" />
              </div>

              <div class="sorting-right">
                <a v-translation="{ type: 'label', code: 'button_change_order'}" class="btn-u change-sorting" @click="changeModalVisibility"></a>

                <sorting-modal v-if="showModal" @changeModalVisibility="changeModalVisibility($event)"></sorting-modal>

                <select ref="sortType" v-model="sorting" v-on:change="ChangeSOrting()">
                  <option value="custom">Mijn Bestellijst</option>
                  <option value="category">Categorie</option>
                  <option value="productcode">Productcode</option>
                </select>
              </div>
            </div>

            <div class="orderlist-table">
                <div class="orderlist-table-header orderlist-header">
                    <div class="cell prod-desc" v-translation="{ type: 'label', code: 'label_description'}"></div>
                    <div class="cell prod-unit" v-translation="{ type: 'label', code: 'label_unit'}"></div>
                    <div class="cell prod-quantity" v-translation="{ type: 'label', code: 'label_amount'}"></div>
                    <div class="cell weeks">
                      <div class="week">-1w</div>
                      <div class="week">-2w</div>
                      <div class="week">-3w</div>
                      <div class="week">-4w</div>
                    </div>
                    <div class="cell cart"></div>
                </div>
                <orderlist-row
                  v-bind:key="orderlistItem.Code"
                  v-for="orderlistItem in orderlistItems"
                  :OrderlistItem="orderlistItem">
                </orderlist-row>
            </div>
        </div>

        <orderbar :totalProductsInCart="totalProductsInCart"></orderbar>
    </div>
</template>

<script>
import OrderListRow from './OrderListRow.vue';
import OrderBar from './OrderBar.vue';
import SortingModal from './SortingModal.vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

export default {
  components: {
    'orderlist-row': OrderListRow,
    'orderbar': OrderBar,
    'sorting-modal': SortingModal
  },
  computed: {
    ...mapState('orderList', ['orderlistItems']),
    ...mapGetters('orderList', ['totalProductsInCart'])
  },
  methods: {
    ...mapMutations('orderList', ['setSearchText', 'setSorting']),
    ...mapActions('orderList', ['initOrderlist', 'getOrderlistItems', 'clearQuantities', 'getCustomSorting']),
    changeSearchText (event) {
      this.setSearchText(event.target.value);
      this.getOrderlistItems();
    },
    changeModalVisibility () {
      if (this.showModal === true) {
        this.showModal = false;
      } else {
        this.getCustomSorting().then(res => {
          this.showModal = true;
        });
      }
    },
    printOrderlist () {
      window.print();
    },
    ChangeSOrting() {
      this.setSorting(this.sorting)
      this.getOrderlistItems();
    }
  },
  created () {
    this.initOrderlist('custom');
    this.getOrderlistItems();
  },
  data () {
    return {
      showModal: false,
      sorting: 'custom'
    }
  }
}
</script>
