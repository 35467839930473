<template>
  <div class="cmp-elastic-product-list utlz-row utlz-reset" :class="layoutType" ref="productListContainer">
    <div class="utlz-col-md-12">
      <!-- BESPOKE DEN: Added additional product counter and wrapped it with h1 -->
      <div class="productlist-header">
        <h1>{{ pageTitle }}</h1>
        <p v-if="!noProductsFound" class="product-counter" v-translation="{ type: 'label', code: 'label_showing_result', interpolations: [pageRange.from + ' - ' + pageRange.to, totalProducts] }"></p>
        <div v-if="noProductsFound" class="search-bar-wrapper sort-options-wrapper no-products searchbar-top">
          <input type="text" :placeholder="placeholderText" @keyup.enter="setQueryString(searchText)" v-model="searchText" class="form-control" />
          <a v-if="queryString.length" @click="clearSearch()" class="clear-search-btn">
            <i class="fa fa-times-circle"></i>
          </a>
          <a class="inputbutton btn search-button" @click="setQueryString(searchText)"></a>
        </div>
      </div>
      <!-- END BESPOKE DEN -->

        <utlz-sorting-options
          id="sorting-options"
          v-if="!noProductsFound"
          @changeLayout="changeLayout($event)"
          @changePageSize="changePageSize($event)"
          @changePageSorting="changePageSorting($event)"
          :pageRange="pageRange"
          :totalProducts="totalProducts"
          :layoutType="layoutType"
          :parentClassRef="'ref-elastic-product-list'"
          :pageSize="pageSizes"
          :pageSorting="sortingOptions">

          <template v-slot:pagination>
            <!-- BESPOKE PAL-54: added searchfield -->
            <div class="search-bar-wrapper">
              <input type="text" :placeholder="placeholderText" @keyup.enter="setQueryString(searchText)" v-model="searchText" class="form-control" />
              <a v-if="queryString.length" @click="clearSearch()" class="clear-search-btn">
                <i class="fa fa-times-circle"></i>
              </a>
              <a class="inputbutton btn search-button" @click="setQueryString(searchText)"></a>
            </div>
            <!-- <utlz-pagination v-if="totalPages > 1" :totalPages="totalPages" :currentPage="currentPage" :scrollToTop="true" @changePage="changePage($event)"></utlz-pagination> -->
            <!-- END BESPOKE -->
          </template>

          <template v-slot:toggle-mobile-filters>
            <a @click="toggleMobileFilters()" class="toggle-filters-btn button small theme-primary show-mobile">
              <i class="fa fa-filter"></i>
              <span v-translation="{ type: 'label', code: 'label_toggle_mobile_filters' }"></span>
            </a>
          </template>

        </utlz-sorting-options>

        <transition-group name="fadein" tag="div" class="list-items-wrapper utlz-row">
          <template v-if="!products.length && !noProductsFound">
            <div v-for="index in (4 * blockSize)" :key="index" class="ph-silhouette product-list-item utlz-col-12" :class="'utlz-col-sm-' + blockSize">
              <span class="silhouette-img square"></span>
              <div class="ph-paragraph">
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
              </div>
            </div>
          </template>
        </transition-group>

        <transition-group name="fade" tag="div" class="list-items-wrapper utlz-row">
            <utlz-product-list-item
              @navigateToProductPage="navigateToProductPage($event)"
              v-for="product in products"
              :key="product.id"
              :product="product"
              :blockSize="blockSize"
              class="product-list-item">
            </utlz-product-list-item>
          </transition-group>

        <utlz-pagination v-if="totalPages > 1" :totalPages="totalPages" :currentPage="currentPage" @changePage="changePage($event)"></utlz-pagination>

        <div v-if="noProductsFound" class="no-products-found">
          <h2 v-translation="{ type: 'label', code: 'label_no_products_found'}"></h2>
        </div>

      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SortingOptions from 'src/components/webshop/sorting-options/SortingOptions.vue';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
import Pagination from 'src/components/ui/pagination/Pagination.vue';
export default {
  components: {
    'utlz-sorting-options': SortingOptions,
    'utlz-pagination': Pagination,
    'utlz-product-list-item': ProductListItem
  },
  // BESPOKE DEN
  // Added searchtext property as v-model property
  data () {
    return {
      searchText: ''
    }
  },
  // END BESPOKE
  computed: {
    // BESPOKE: Added setQueryString
    ...mapState('elastic', ['products', 'filters', 'stringProperties', 'layoutType', 'blockSize', 'pageSizes', 'queryString', 'sortingOptions']),
    ...mapGetters('elastic', ['pageTitle', 'totalPages', 'currentPage', 'pageRange', 'totalProducts', 'noProductsFound']),
    // BESPOKE DEN-DI: Added placeholder text
    placeholderText () {
      return `${window.vue.translations.label.label_search_in_context} ${this.pageTitle}...`;
    }
  },
  methods: {
    // BESPOKE: Added setQueryString
    ...mapActions('elastic', ['initElastic', 'updateFilters', 'changePage', 'changeLayout', 'changePageSize', 'toggleMobileFilters', 'setQueryString', 'changePageSorting']),
    // END BESPOKE
    navigateToProductPage (href) {
      location.href = href;
      localStorage.setItem('scrollPositionTop', window.pageYOffset);
    },
    clearSearch () {
      this.searchText = '';
      this.setQueryString('');
    }
  },
  created () {
    this.initElastic();
  },
  updated () {
    window.scrollTo(0, localStorage.getItem('scrollPositionTop'));
    localStorage.setItem('scrollPositionTop', 0);
  }
};
</script>

<style>
</style>
