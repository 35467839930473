import axios from 'axios';
import { OrderlistItem } from './../../models/orderlist-item.model';

const state = {
  addDeletedProductsUrl: '/Bespoke/Account/OrderListMethods.aspx/AddCustomDeleteProduct',
  deleteDeletedProductsUrl: '/Bespoke/Account/OrderListMethods.aspx/DeleteCustomDeleteProduct',
  customOrderProductsUrl: '/Bespoke/Account/OrderListMethods.aspx/getChangeSortingDatasource',
  updateSortProductsUrl: '/Bespoke/Account/OrderListMethods.aspx/UpdateSortedProducts',
  getOrderListProductsUrl: '/Bespoke/Account/OrderListMethods.aspx/GetOrderlistProducts',
  addProductsToShoppingCartUrl: '/Bespoke/Account/OrderListMethods.aspx/AddProductsToShoppingCart',
  orderlistItems: [],
  sortedListItems: [],
  deletedListItems: [],
  searchText: '',
  sorting: ''
};

const getters = {
  addDeletedProductsEndpoint (state) {
    return state.addDeletedProductsUrl;
  },
  deleteDeletedProductsEndpoint (state) {
    return state.deleteDeletedProductsUrl;
  },
  customOrderProductsEndpoint (state) {
    return state.customOrderProductsUrl;
  },
  updateSortProductsEndpoint (state) {
    return state.updateSortProductsUrl;
  },
  getOrderListProductsEndpoint (state) {
    return state.getOrderListProductsUrl;
  },
  addProductsToShoppingCartEndpoint (state) {
    return state.addProductsToShoppingCartUrl;
  },
  getOrderListItems (state) {
    return state.orderlistItems;
  },
  getSearchText (state) {
    return state.searchText;
  },
  getSorting (state) {
    return state.sorting;
  },
  sortedListItems (state) {
    return state.sortedListItems;
  },
  deletedListItems (state) {
    return state.deletedListItems;
  },
  totalProductsInCart (state) {
    let total = 0;
    state.orderlistItems.forEach(item => {
      total += item.InCart;
    });
    return total;
  }
};

const mutations = {
  setSorting (state, sortingType) {
    state.sorting = sortingType;
  },
  setOrderlistItems (state, payload) {
    state.orderlistItems = payload;
  },
  setOrderQuantity (state, { productcode, quantity }) {
    state.orderlistItems.find(obj => obj.Code === productcode).Quantity = quantity;
  },
  setOrderlistItemsInCart (state, { productcode, quantity }) {
    state.orderlistItems.find(obj => obj.Code === productcode).InCart += quantity;
  },
  setSearchText (state, payload) {
    state.searchText = payload;
  },
  setSortedListItems (state, payload) {
    state.sortedListItems = payload.map(item => {
      item.isMoved = false;
      return item;
    });
  },
  setDeletedListItems (state, payload) {
    state.deletedListItems = payload;
  },
  updateSortedListItems (state, { prodCodesInOrder }) {
    let sortedListItems = [...state.sortedListItems];
    let newSortedListItems = [];
    // create new array based on an array of prod codes
    prodCodesInOrder.forEach(prodCode => {
      sortedListItems.forEach(listItem => {
        if (listItem.Code === prodCode) { newSortedListItems.push(listItem); }
      });
    });
    state.sortedListItems = newSortedListItems;
  }
}

const actions = {
  initOrderlist ({ commit }, sortingType) {
    commit('setSorting', sortingType)
  },
  getOrderlistItems ({ commit, getters, state }) {
    const obj = {};
    obj.SearchText = getters.getSearchText;
    obj.Sorting = getters.getSorting;

    axios.post(getters.getOrderListProductsEndpoint, obj)
      .then(res => {
        const orderlistItems = res.data.d.products.map(orderlistItem => new OrderlistItem(orderlistItem));
        commit('setOrderlistItems', orderlistItems);
      });
  },
  addProductsToShoppingCart ({ commit, getters, dispatch }) {
    const payload = [];
    const products = getters.getOrderListItems.filter(obj => obj.Quantity > 0);

    products.forEach(element => {
      const obj = {
        prod_code: element.Code,
        ord_qty: element.Quantity,
        unit_code: element.UnitCode,
        prod_comment: ' '
      };
      payload.push(obj);
    });

    dispatch('shoppingCart/addToCart', payload, { root: true }).then(res => {
      dispatch('getOrderlistItems');
    });
  },
  clearQuantities ({ state }) {
    state.orderlistItems.forEach(function (obj) { obj.Quantity = 0 });
  },
  getCustomSorting ({ getters, commit }) {
    return new Promise((resolve, reject) => {
      axios.post(getters.customOrderProductsEndpoint, {})
        .then(res => {
          commit('setSortedListItems', res.data.d.sortingProducts);
          commit('setDeletedListItems', res.data.d.deletedProducts);
          resolve(true);
        });
    });
  },
  updateSortedListItems ({ commit }, prodCode) {
    commit('updateSortedListItems', prodCode);
  },
  saveCustomOrderlist ({ getters, dispatch }, { sortedListItems, deletedListItems }) {
    // Save sorted items
    let payloadSortedListItems = [];
    sortedListItems.forEach((item, index) => {
      payloadSortedListItems.push({ Code: item.Code, SortNumber: index });
    });

    axios.post(getters.updateSortProductsEndpoint, { Products: payloadSortedListItems })
      .then(res => {
      });

    // Save deleted items
    let payloadDeletedListItems = [];
    deletedListItems.forEach((item) => {
      payloadDeletedListItems.push(item.Code);
    });
    axios.post(getters.deleteDeletedProductsEndpoint, { ids: payloadDeletedListItems })
      .then(res => {
        dispatch('getOrderlistItems');
      });
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
