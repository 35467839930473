<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">  
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

          <template
            v-if="screenWidth >= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <figure class="img-square prod-img">
            <a :href="product.targetUrl" class="clickthrough">
              <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
            </a>
          </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">        
        <div class="title-wrapper">
          <a :href="product.targetUrl" class="clickthrough">
            <template v-if="screenWidth <= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>
            <!-- BESPOKE PAL-70: Disabled product code -->
              <!-- <div class="product-code">
                <span class="value">{{ product.id }}</span>
              </div> -->
              <!-- END BESPOKE PAL-70 -->
            <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
          </a>

          <!-- BESPOKE PAL: Wrapped order product in div.action-btns  -->
          <div class="action-btns">
            <!-- BESPOKE PAL: Moved favorites control here -->
            <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control>
            <!-- END BESPOKE PAL -->
            <div class="ph-order-product">
              <transition name="fade">
                <utlz-order-product
                  v-if="showOrderProductControl"
                  :product="computedProduct"
                  :parentClassRef="'ref-product-list-item'"
                  @orderButtonClick="handleOrderButtonClick">
                </utlz-order-product>
              </transition>

              <!-- BESPOKE PAL: Disable out of stock message since customer does not show stock -->
              <!-- <transition name="fade">
                <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
                  <i class="fa fa-warning"></i>
                  <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                </p>
              </transition> -->
              <!-- END BESPOKE PAL -->
              
            </div>
          </div>
          <utlz-product-variant
            v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
            :product="computedProduct"
            :productVariants="product.productVariants"
            @selectedProduct="selectedProduct($event)"
          ></utlz-product-variant>

          
        </div>

        <div class="ph-product-price" v-if="product.prices.length">
            <utlz-product-price
              :prices="product.prices[0]"
              :parentClassRef="'ref-product-list-item'"
            ></utlz-product-price>
          </div>
        <!-- END BESPOKE PAL -->

        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->
        <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :stock="product.stock"
          :stockTotal="product.stock"
        ></utlz-product-stock>

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
// BESPOKE
// Added product banners control, which should be in a future release
import ProductBanners from 'src/components/webshop/banners/ProductBanners.vue';
// END BESPOKE
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl,
    'utlz-product-banners': ProductBanners
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      stockProduct: this.product.customBooleans.STOCK_PRODUCT,
      activeProductVariantId: null
    };
  },
  computed: {
    ...mapState('elastic', ['layoutType']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth', 'condenseProductList']),    
    isSalesAction () { 
      return this.product.prices.length > 0 ? this.product.prices[0].isSalesAction : false;
    },
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      if (!this.stockLimit || !this.stockProduct) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    },
    productImageBanners () {
      return this.product.productImageBanners.filter(banner => banner.show === true);
    }
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    },
    // Changed emitted event to action that sends data to the dataLayer before redirecting
    ...mapActions('elastic', ['pushProductClickToDataLayer']),
    navigateToProductPage() {
      return this.pushProductClickToDataLayer(this.product.id);
    },
    handleOrderButtonClick() {
      this.$emit('orderButtonClick')
    }
  }
};
</script>

<style>
</style>
